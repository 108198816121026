import React from "react";
import Uppy from "@uppy/core";
import fr from "@uppy/locales/lib/fr_FR";
import api, { env, dev_api } from "../../util/Environment";
import i18next from "i18next";
import { Dashboard } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import { messageSuccess } from "../../util/Toast";
// Don't forget the CSS: core and the UI components + plugins you are using.
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

// Don’t forget to keep the Uppy instance outside of your component.
export const uppy = new Uppy().use(XHR, {});

const languages = {
  fr,
};

export const setEndPoint = (route, method = "POST", data) => {
  uppy.getPlugin("XHRUpload").setOptions({
    endpoint: `${api}${route}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.token}`,
    },
  });
};

export const upload = (onClose, callBack) => {
  uppy.upload();
  uppy.once("upload-success", function (file, responce) {
    uppy.cancelAll();
    uppy.setMeta({});
    onClose();
    callBack();
    messageSuccess();
  });
};

uppy.on("upload-error", (file, error) => {
  console.error("Error uploading file:", error);
});
export default function UppyComponent({
  allowedFileTypes = [],
  maxNumberOfFiles,
  hideUploadButton = true,
  maxFileSize = 2048000,
  height = "130px",
}) {
  uppy.setOptions({
    restrictions: {
      allowedFileTypes,
      maxNumberOfFiles,
      maxFileSize,
    },
  });

  return (
    <Dashboard
      uppy={uppy}
      width="100%"
      height={height}
      locale={languages[i18next.language]}
      hideUploadButton={hideUploadButton}
      plugins={["XHR"]}
      proudlyDisplayPoweredByUppy={false}
    />
  );
}
