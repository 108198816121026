import { TextField, Button, CircularProgress } from "@mui/material";
import { isEmpty } from "../util/Validation";
import { useState, useMemo, useEffect, useCallback } from "react";
import { ROLES, LANGUAGES, SKILLS, DOWNLOAD } from "../util/Routes/Routes";
import { messageError, messageSuccess } from "../util/Toast";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import Get from "../util/Request/Get";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UppyComponent, {
  uppy,
  setEndPoint,
  upload,
} from "../components/Uppy/Uppy";
import { useTranslation } from "react-i18next";
import TabsComponent from "../components/Tabs/Tabs";
import Post from "../util/Request/Post";
import Put from "../util/Request/Put";
import BaseModal from "./BaseModal";

export default function SkillsModal({ open, onClose, data: skillData }) {
  const { t } = useTranslation();

  const [translationText, setTranslationText] = useState({});
  const [errors, setErrors] = useState({});
  const [tabErrors, setTabErrors] = useState({});
  const queryClient = useQueryClient();

  const resetFields = () => {
    uppy.cancelAll();
    uppy.setMeta({});
    setErrors({});
    setTranslationText({});
    setTabErrors({});
  };
  const getSkillById = () => Get({ route: SKILLS, key: skillData?.id });
  const {
    data: skill_data,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["skill", skillData?.id],
    queryFn: getSkillById,
    enabled: false,

    onError: (error) => {
      messageError();
    },
  });

  const downloadSkillImage = (skill_data) =>
    Get({
      route: DOWNLOAD,
      key: skill_data?.skill.url,
    });

  const { refetch: fetchImage } = useQuery({
    queryKey: ["skillImage", skill_data],
    queryFn: ({ queryKey }) => downloadSkillImage(queryKey[1]),
    enabled: false,
    onSuccess: async ({ fileContent, mimeType }) => {
      const imageName = skill_data.skill.url.split("/").pop();

      const file = new File([fileContent], imageName, {
        type: mimeType,
      });
      uppy.addFile({
        name: imageName,
        type: mimeType,
        data: file,
        preview: skill_data.skill.url,
      });
    },
    onError: (error) => {
      messageError();
    },
  });

  /* Create request */
  const createSkill = (translations) =>
    Post({
      route: SKILLS,
      body: {
        translations: JSON.stringify(translations),
      },
    });

  /* Update request */
  const updateSkill = (translations) =>
    Put({
      route: SKILLS,
      key: skill_data.skill.id,
      body: {
        translations: JSON.stringify(translations),
      },
    });

  const mutation = useMutation({
    mutationFn: (translations) =>
      skillData ? updateSkill(translations) : createSkill(translations),
    onSuccess: (data) => {
      console.log("data:", data);
      queryClient.invalidateQueries({ queryKey: ["skills"] });
      queryClient.setQueryData(["skill_data", skillData?.id], data.skill);
      onClose();
      messageSuccess(
        skillData ? "updated successfully" : "created successfully"
      );
    },
    onError: (error, variables, context) => {
      messageError(skillData ? "failed to update" : "failed to create");
    },
  });

  // get skill data on edit
  useEffect(() => {
    if (open && skillData) {
      refetch();
    }
  }, [open, refetch, skillData]);

  // reset field on modal close
  useEffect(() => {
    resetFields();
  }, [open]);

  //fill the fields when opening modal on edit
  useEffect(() => {
    if (skill_data?.skill && open) {
      if (skill_data.skill.url && !uppy.getFiles().length) {
        fetchImage();
      }
      const translations = skill_data?.translations.reduce(
        (accumulator, { language_code, label, value }) => {
          const split = label.split(".");
          if (!accumulator[language_code]) {
            accumulator[language_code] = {};
          }

          accumulator[language_code][split[split.length - 1]] = value;
          return accumulator;
        },
        {}
      );

      setTranslationText(translations);
    }
  }, [skill_data, open, fetchImage]);

  const getLanguages = () => Get({ route: LANGUAGES });
  const { data: languagesData } = useQuery({
    queryKey: ["languages"],
    queryFn: getLanguages,
    onError: (error) => {
      messageError();
    },
  });
  const getRoles = () => Get({ route: ROLES });
  const { data } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
    onError: (error) => {
      messageError();
    },
  });

  // init translations json on create, using the languages as keys
  useEffect(() => {
    if (languagesData && !skillData) {
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        if (!translationText[iso_code]) {
          translationText[iso_code] = {};
        }
      });
      setTranslationText(translationText);
    }
  }, [languagesData, translationText, skillData]);

  const typeOptions = useMemo(() => {
    return (
      data?.list.filter(
        ({ label }) => label !== "admin" && label !== "moderator"
      ) || []
    );
  }, [data]);
  const [type, setType] = useState("");
  useEffect(() => {
    if (typeOptions && !skill_data) {
      setType(typeOptions[0]?.id);
    }
  }, [typeOptions, skill_data]);
  const validation = useCallback(() => {
    Object.keys(translationText)?.forEach((language) => {
      if (isEmpty(translationText[language].title)) {
        if (!errors[language]) errors[language] = {};
        errors[language].title = "required";
        tabErrors[t(`shared.language.${language}`)] = {};
      } else {
        delete errors[language]?.title;
      }
      if (isEmpty(translationText[language].description)) {
        if (!errors[language]) errors[language] = {};
        errors[language].description = "required";
        tabErrors[t(`shared.language.${language}`)] = {};
      } else {
        delete errors[language]?.description;
      }

      if (errors[language] && !Object.values(errors[language])?.length) {
        delete errors[language];
        delete tabErrors[t(`shared.language.${language}`)];
      }
    });
    setTabErrors({ ...tabErrors });
    setErrors({ ...errors });
  }, [translationText, t, tabErrors, errors]);

  const tabContent = useMemo(() => {
    const object = {};
    if (languagesData)
      Object.values(languagesData?.list)?.forEach(({ iso_code }) => {
        object[t(`shared.language.${iso_code}`)] = (
          <Grid container spacing={2} direction={"column"}>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_skill`)}
                fullWidth
                value={translationText[iso_code]?.title || ""}
                onChange={(e) => {
                  translationText[iso_code].title = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.title}
                helperText={errors[iso_code]?.title}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label={t(`component.shared.${iso_code}_`)}
                rows={4}
                multiline
                fullWidth
                value={translationText[iso_code]?.description || ""}
                onChange={(e) => {
                  translationText[iso_code].description = e.target.value;
                  setTranslationText({ ...translationText });
                  validation();
                }}
                error={!!errors[iso_code]?.description}
                helperText={errors[iso_code]?.description}
              />
            </Grid>
          </Grid>
        );
      });
    return object;
  }, [languagesData, t, translationText, errors, validation]);

  const onSubmit = () => {
    validation();

    if (!Object.values(errors).length) {
      if (uppy.getFiles().length) {
        uppy.setMeta({
          translations: JSON.stringify(translationText),
          _method: skill_data ? "PUT" : "POST",
          "content-type": uppy.getFiles()[0].type,
        });
        setEndPoint(`${SKILLS}${skill_data ? "/" + skill_data.skill.id : ""}`);
        upload(onClose, () => {
          queryClient.invalidateQueries({
            queryKey: ["skills"],
          });
          queryClient.setQueryData(["skill_data", skillData?.id], data.skill);
        });
      } else {
        mutation.mutate(translationText);
      }
    }
  };

  return (
    <>
      <BaseModal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        {isFetching && skillData ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <h2>
              {!skillData ? t("skill.create_skill") : t("skill.update_skill")}
            </h2>
            <Grid container spacing={2} direction={"column"}>
              <Grid xs={12}>
                <UppyComponent
                  maxNumberOfFiles={1}
                  allowedFileTypes={["image/*", "image/svg+xml"]}
                />
              </Grid>
              <Grid xs={12}>
                <TabsComponent
                  tabContent={tabContent}
                  errors={Object.keys(tabErrors)}
                />
              </Grid>
              <Grid>
                <Button variant="contained" onClick={onSubmit} color="success">
                  {!skillData
                    ? t("settings.button.create")
                    : t("settings.button.update")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </BaseModal>
    </>
  );
}
